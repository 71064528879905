import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IconCal from '../assets/icon-cal.png';
import IconPin from '../assets/icon-pin.png';

import '../styles/DateFilter.css';

const CustomDatePickerInput = React.forwardRef(({ value, onClick, placeholder, className }, ref) => (
  <div className="custom-date-picker-input" onClick={onClick} ref={ref}>
    <img src={IconCal} alt="Calendar Icon" className={`date-picker-icon ${className}`} />
    <input
      type="text"
      value={value}
      readOnly
      className={`date-picker-text-input ${className}`}
      placeholder={placeholder}
    />
  </div>
));

export default function DateFilter({
  startDate,
  endDate,
  timeRange,
  onStartDateChange,
  onEndDateChange,
  onTimeRangeChange,
  selectedVenue
}) {
  return (
    <div className="date-filter-container">
        <div className="venue-name-group">
            <img className="icon" src={IconPin} alt="Venue" />
            <p className="selected-venue-text">{selectedVenue}</p>
        </div>
        <div className="custom-date-group">
            <DatePicker
            selected={startDate}
            onChange={(date) => onStartDateChange(date)}
            customInput={<CustomDatePickerInput className="custom-date-picker" />}
            />
        </div>
        <div className="date-input">
            <DatePicker
            className="date-picker"
            selected={endDate}
            onChange={(date) => onEndDateChange(date)}
            placeholderText="End date"
            />
        </div>
    </div>
  );
}