import React, { useState } from 'react';
import '../styles/Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header-container">
      <div className="header-nav-container">
        <div className="header-text">
          <h1 className="logo">
            <a href="/">BestDay2Marry</a>
          </h1>
        </div>

        <button 
          className={`hamburger ${isMenuOpen ? 'active' : ''}`} 
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <nav className={`bestday2marry-nav ${isMenuOpen ? 'nav-open' : ''}`}>
          <a href="/">About</a>
          <a href="/solutions">Solutions</a>
          
          <div className="dropdown">
            <a href="/pricing/businesses">Pricing</a>
            <div className="dropdown-content">
              <a href="/pricing/businesses">Businesses</a>
              <a href="/pricing/couples">Couples</a>
            </div>
          </div>

          <a href="/signin">Sign In</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
