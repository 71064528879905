export const TEMP_UNIT_KEY = 't2m_units';
export const PRECIP_UNIT_KEY = 'precipitation_units';
export const WIND_UNIT_KEY = 'ws2m_units';

export const CELSIUS = 'C';
export const FAHRENHEIT = 'F';

export const INCHES = 'inches';
export const CM = 'cm';

export const MPH = 'mph';
export const KMPH = 'kmph';

export const IMPERIAL = 'imperial';
export const METRIC = 'metric';

export const IMPERIAL_UNITS = {
  [TEMP_UNIT_KEY]: FAHRENHEIT,
  [PRECIP_UNIT_KEY]: INCHES,
  [WIND_UNIT_KEY]: MPH,
};

export const METRIC_UNITS = {
  [TEMP_UNIT_KEY]: CELSIUS,
  [PRECIP_UNIT_KEY]: CM,
  [WIND_UNIT_KEY]: KMPH,
};

export const ONE_DAY_FUTURE = 1000 * 60 * 60 * 24;