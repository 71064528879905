import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderScreenshot from '../assets/header-screenshot.png'; 
import DateRangeBox from '../assets/date-range-box.png'; 
import RangeFilterBox from '../assets/range-filter-box.png'; 
import TrackersPreview from '../assets/trackers-preview.png'; 
import Header from '../components/Header';
import CopyrightFooter from '../components/CopyrightFooter';
import '../styles/SolutionsPage.css';

const SolutionsPage = () => {
  const [selectedOption, setSelectedOption] = useState('quickly');
  const navigate = useNavigate();

  const handleTryNowPersonalClick = () => {
    navigate('/pricing/couples');  
  };

  const handleTryNowBusinessClick = () => {
    navigate('/pricing/businesses');  
  };

  useEffect(() => {
    document.body.classList.add('solutions-page-body');
    return () => {
      document.body.classList.remove('solutions-page-body');
    };
  }, []);

  return (
    <div className="solutions-page">
      {/* Header/Navbar Section */}
      <Header />
      
      {/* Inner Header Section */}
      <header className="solutions-header">
        <h1 className="solutions-title">Solutions</h1>
        <p className="solutions-subheading">
          Plan wedding dates around the weather and ensure smooth execution with weather insights
        </p>
      </header>

      {/* Main Image Section */}
      <section className="solutions-main">
        <div className="main-image-container">
          <img src={HeaderScreenshot} alt="Weather Insights Screenshot" className="main-image" />
        </div>
      </section>

      {/* Toggle and Description Section */}
      <section className="solutions-toggle">
        <div className="toggle-options">
          <div
            className={`toggle-option ${selectedOption === 'quickly' ? 'active' : ''}`}
            onClick={() => setSelectedOption('quickly')}
          >
            Quickly assess upcoming weather conditions for important event dates
          </div>
          <div
            className={`toggle-option ${selectedOption === 'easily' ? 'active' : ''}`}
            onClick={() => setSelectedOption('easily')}
          >
            Easily view weather trends by day, weekend, week or month
          </div>
        </div>

        {/* Show the correct box based on selection */}
        {selectedOption === 'quickly' && (
          <div className="date-range-box">
            <img src={DateRangeBox} alt="Date Range Box" />
          </div>
        )}
        {selectedOption === 'easily' && (
          <div className="range-filter-box">
            <img src={RangeFilterBox} alt="Range Filter Box" />
          </div>
        )}
      </section>

      <section className="business-product-section">
        <div className="trackers-preview">
          <img src={TrackersPreview} alt="Trackers Preview" /> {/* adjust to different image, just placeholder for now */}
        </div>
        <div className="business-product-text">
          <h2 className="feature-title">For Businesses</h2>
          <h3 className="feature-subheading">What we offer</h3>
          <p>
          At BestDay2Marry, we empower businesses with precision forecasting for any chosen venue or location, enriched with comprehensive insights across a variety of climate variables. 
          Our tiered subscription plans are designed to accommodate diverse business needs, offering flexibility and value. We are also ready to collaborate with you to tailor a custom plan 
          that perfectly aligns with your specific requirements, ensuring you have the precise tools to make every event a success. 
          </p>
          <button className="primary-button try-button" onClick={handleTryNowBusinessClick}>Try Now!</button>
        </div>
      </section>

      {/* Feature Sneak Peek Section */}
      <section className="feature-sneak-peek">
        <div className="sneak-peek-text">
          <h2 className="feature-title">For individuals</h2>
          <h3 className="feature-subheading">Feature sneak peek</h3>
          <p>
            We’re constantly working to add new features to help you plan a dream wedding. Features like personalized weather trackers and event-specific alerts coming soon.
          </p>
          <button className="primary-button try-button" onClick={handleTryNowPersonalClick}>Try Now!</button>
        </div>
        <div className="trackers-preview">
          <img src={TrackersPreview} alt="Trackers Preview" />
        </div>
      </section>
      <CopyrightFooter />
    </div>
  );
};

export default SolutionsPage;
