import { useEffect, useState } from 'react';
import Cookies from 'js-cookies';

const ONE_DAY_FUTURE = 1000 * 60 * 60 * 24;

function convertDate(dateObject) {
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function mapServerData(rawData) {
  return rawData.map(item => {
    const timestamp = item['time'];

    return {
      date: new Date(timestamp),
      precipitation: item['PRECTOTCORR_Average'],
      precipitationMin: item['PRECTOTCORR_Min'],
      precipitationMax: item['PRECTOTCORR_Max'],
      temperature: item['T2M_Average'],
      temperatureMin: item['T2M_Min'],
      temperatureMax: item['T2M_Max'],
      windSpeed: item['WS2M_Average'],
      windSpeedMin: item['WS2M_Min'],
      windSpeedMax: item['WS2M_Max'],
      windDirection: item['WD2M_Average'],
      windDirectionMin: item['WD2M_Min'],
      windDirectionMax: item['WD2M_Max'],
      watts: item['ALLSKY_SFC_SW_DWN_Average'],
      wattsMin: item['ALLSKY_SFC_SW_DWN_Min'],
      wattsMax: item['ALLSKY_SFC_SW_DWN_Max'],
      cloud: item['CLOUD_AMT_Average'],
      cloudMin: item['CLOUD_AMT_Min'],
      cloudMax: item['CLOUD_AMT_Max'],
      rh2m: item['RH2M_Average'],
      rh2mMin: item['RH2M_Min'],
      rh2mMax: item['RH2M_Max'],
    };
  });
}

/**
 * A custom hook to fetch forecast data from the server
 * @param {string} userId - The user's ID
 * @param {Date} startDate - The start date for the forecast
 * @param {Date} endDate - The end date for the forecast
 * @param {Object} unitSettings - The user's unit settings
 * @returns {Object} - { data, loading, error }
 */
export function useForecastData(userId, startDate, endDate, unitSettings) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!startDate || !endDate || !userId) {
      setLoading(false);
      return;
    }

    async function fetchData() {
      setLoading(true);
      try {
        let finalEndDate = endDate;
        if (endDate === null) {
          finalEndDate = new Date(startDate.getTime() + ONE_DAY_FUTURE);
        }

        let scale = (finalEndDate - startDate <= ONE_DAY_FUTURE) ? 'hourly' : 'daily';

        const session_cookie = Cookies.getItem('session_cookie');
        const url = `https://junbispark.net/get_dashboard_data/?user_id=${userId}&start_time=${convertDate(startDate)}&end_time=${convertDate(finalEndDate)}&scale=${scale}&session_cookie=${session_cookie}&t2m_units=${unitSettings.t2m_units}&precipitation_units=${encodeURIComponent(unitSettings.precipitation_units)}&ws2m_units=${unitSettings.ws2m_units}`;

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Fetch failed with status ${response.status}`);
        }

        const jsonData = await response.json();
        if (!jsonData.rv) {
          throw new Error('Missing rv in the response');
        }

        const mapped = mapServerData(jsonData.rv);
        const sorted = mapped.sort((a, b) => a.date - b.date);
        setData(sorted);

      } catch (err) {
        console.error('Error fetching forecast data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [userId, startDate, endDate, unitSettings]);

  return { data, loading, error };
}