import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ExpandedTracker from './pages/ExpandedTracker';
import EmailVerificationPage from './pages/EmailVerificationPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage.js';
import LandingPage from './pages/LandingPage.js';
import VenueSelection from './pages/VenueSelection.js';
import SettingsPage from './pages/SettingsPage'; 
import AddVenue from './pages/AddVenue';
import SolutionsPage from './pages/SolutionsPage.js';
import PricingPage from './pages/PricingPage.js';
import PricingPageCouples from './pages/PricingPageCouples.js';
import PricingPageBusinesses from './pages/PricingPageBusinesses.js';
import TrackerPage from './pages/TrackerPage.js';
import { AuthProvider, ProtectedRoute, VenueContext } from './utils/authprovider';
import IconHeart from './assets/icon-heart.png';

import './App.css';


const AppContent = () => {
    // Stores the venue selected by the user in the settings page
    const [activeVenue ] = useState(null);
    console.log('____________TOP LEVEL APP JS ACTIVE VENUE IS', activeVenue)

    useEffect(() => {
        console.log('-------IN APP JS ACTIVE VENUE CHANGED TO', activeVenue)
    }, [activeVenue])

    const onVenueSelect = async (user_id, newVenue) => {
        console.log('onVenueSelect called with new venue:', newVenue)
        // setActiveVenue(newVenue)

        
    }

    useEffect(() => {
        document.title = "BestDay2Marry"; 
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = IconHeart; 
        document.getElementsByTagName('head')[0].appendChild(link);
    }, []);

    const [trackers, setTrackers] = useState([
        {
            id: 1,
            venue: 'Beringer Vineyards',
            startDate: new Date(),
            endDate: new Date(),
            weatherConditions: [],
            data: [],
            alerts: []
        }
    ]);
 
     const handleSaveTracker = (newTracker) => {
         setTrackers(prevTrackers => {
             const existingIndex = prevTrackers.findIndex(tracker => tracker.id === newTracker.id);
             if (existingIndex >= 0) {
                 const updatedTrackers = [...prevTrackers];
                 updatedTrackers[existingIndex] = newTracker;
                 return updatedTrackers;
             }
             return [...prevTrackers, newTracker];
         });
     };
 
     const [settings, setSettings] = useState([
         {
             t2m_units: 'F',
             ws2m_units: 'ws2m', // this is wrong, will send correct units via text
             precipitation_units: 'inches/hr',
         }
     ]);

    return (
        <div className="App">
            <AuthProvider>
                <VenueContext.Provider value={activeVenue}>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/signin" element={<SignInPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/pricing" element={<PricingPage />} />
                    <Route path="/pricing/businesses" element={<PricingPageBusinesses />} />
                    <Route path="/pricing/couples" element={<PricingPageCouples />} />
                    <Route path="/solutions" element={<SolutionsPage />} />
                    {/* <Route path="/mobile-verification" element={<MobileVerificationPage />} /> */}
                    {/* <Route path="/trackers" element={<TrackerList trackers={trackers} onSave={handleSaveTracker} />} /> */}
                    {/* <Route element = {<ProtectedRoute/>}>
                        <Route path="/user_verification/:user_id" element={<EmailVerificationPage />} />
                        <Route path='/venue_selection/:user_id' element={<VenueSelection/>} />
                        <Route path="/dashboard/:user_id" element={<ExpandedTracker trackers={trackers} onSave={handleSaveTracker} />} />
                    </Route> */}
                    {/* <Route path="/expandedtracker/settings" element={<SettingsPage />} /> */}
                    <Route element = {<ProtectedRoute/>}>
                        <Route path="/user_verification/:user_id" element={<EmailVerificationPage />} />
                        <Route path='/venue_selection/:user_id' element={<VenueSelection/>} />
                        <Route path='add_venue/:user_id' element={<AddVenue/>} />
                        <Route path="/dashboard/:user_id" element={<ExpandedTracker trackers={trackers} onSave={handleSaveTracker} settings={settings} activeVenue={activeVenue} />} />
                        <Route path="/tracker/:user_id" element={<TrackerPage trackers={trackers} settings={settings} activeVenue={activeVenue} />} />
                        <Route path="/settings/:user_id" element={<SettingsPage settings={setSettings} activeVenue={activeVenue} onVenueSelect={onVenueSelect} />} />
                    </Route>
                </Routes>
                </VenueContext.Provider>
            </AuthProvider>
        </div>
    )
}

const App = () => {
    // let { user_id } = useParams();

    // // Stores the venue selected by the user in the settings page
    // const [activeVenue, setActiveVenue] = useState(null);
    // console.log('____________TOP LEVEL APP JS ACTIVE VENUE IS', activeVenue)

    // useEffect(() => {
    //     console.log('-------IN APP JS ACTIVE VENUE CHANGED TO', activeVenue)
    // }, [activeVenue])

    // const onVenueSelect = (newVenue) => {
    //     console.log('onVenueSelect called with new venue:', newVenue)
    //     setActiveVenue(newVenue)
    // }

    // useEffect(() => {
    //     document.title = "BestDay2Marry"; 
    //     const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    //     link.type = 'image/x-icon';
    //     link.rel = 'shortcut icon';
    //     link.href = IconHeart; 
    //     document.getElementsByTagName('head')[0].appendChild(link);
    //   }, []);

    // const [trackers, setTrackers] = useState([
    //     {
    //         id: 1,
    //         venue: 'Beringer Vineyards',
    //         startDate: new Date(),
    //         endDate: new Date(),
    //         weatherConditions: [],
    //         data: [],
    //         alerts: []
    //     }
    // ]);

    // const handleSaveTracker = (newTracker) => {
    //     setTrackers(prevTrackers => {
    //         const existingIndex = prevTrackers.findIndex(tracker => tracker.id === newTracker.id);
    //         if (existingIndex >= 0) {
    //             const updatedTrackers = [...prevTrackers];
    //             updatedTrackers[existingIndex] = newTracker;
    //             return updatedTrackers;
    //         }
    //         return [...prevTrackers, newTracker];
    //     });
    // };

    // const [settings, setSettings] = useState([
    //     {
    //         t2m_units: 'F',
    //         ws2m_units: 'ws2m', // this is wrong, will send correct units via text
    //         precipitation_units: 'inches/hr',
    //     }
    // ]);

    return (
        <Router>
            {/* <div className="side-menu">
                <Routes>
                    <Route path="/settings" element={<SettingsPage />} /> 
                </Routes>
                <a href="#home" className="side-menu-item">
                    <img className="small-icon" src={IconHome} alt="Home" />
                </a>
                <a href={`/settings/${user_id}`} className="side-menu-item">
                    <img className="small-icon" src={IconCog} alt="Settings" />
                </a>
            </div> */}
            <AppContent />
        </Router>
    );
};

export default App;
