import React from 'react';
import { TAB_CONFIGS } from '../misc/tabConfigs';

import IconTempMax from '../assets/icon-temp-max-color.png';
import IconTempMin from '../assets/icon-temp-min-color.png';
import IconTempMaxLite from '../assets/icon-temp-max-lite.png';
import IconTempMinLite from '../assets/icon-temp-min-lite.png';

import '../styles/TabSelector.css';

export default function TabSelector({ activeTab, onTabChange }) {
  const filteredConfigs = TAB_CONFIGS.filter(
    (tab) => tab.id !== 'temperatureMin' && tab.id !== 'temperatureMax'
  );
  
  return (
    <div className="tabs">
      {filteredConfigs.map((tab) => (
        <button
          key={tab.id}
          className="tab-button"
          onClick={() => onTabChange(tab.id)}
        >
          <img
            src={activeTab === tab.id ? tab.iconActive : tab.iconInactive}
            alt={tab.alt}
          />
        </button>
      ))}
      <div className="tab-divider"></div>
      <button
        className="tab-button"
        onClick={() => onTabChange('temperatureMin')}
      >
        <img
          src={activeTab === 'temperatureMin' ? IconTempMin : IconTempMinLite}
          alt="Temperature Min"
        />
      </button>
      <button
        className="tab-button"
        onClick={() => onTabChange('temperatureMax')}
      >
        <img
          src={activeTab === 'temperatureMax' ? IconTempMax : IconTempMaxLite}
          alt="Temperature Max"
        />
      </button>
    </div>
  );
}