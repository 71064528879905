import React, { useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import '../styles/EmailVerificationPage.css'; 

const EmailVerificationPage = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationError] = useState('');
    const navigate = useNavigate();
    const {user_id} = useParams();
    // const {user_id} = useAuth();

    const handleVerify = async (event) => {
        event.preventDefault();

        try {
            const url = `https://junbispark.net/check_passcode/?user_id=${user_id}&passcode=${verificationCode}`;    
            fetch(url).then((data) => {
                return data.json()
            }).then((data) => {
                if (data['status'] === 'success') {
                    console.log(data);
                    navigate(`/venue_selection/${user_id}`);
                }
            })
        } catch (error) {
            console.error('Error:', error);
        }
    };

    /* const handleResendCode = async () => {
        try {
            const url = `https://junbispark.net/email_verification/?user_id=${user_id}`;    
            await fetch(url).then((data) => {
                return data.json();
            }).then((data) => {
                if (data['status'] === 'success') {
                    console.log("success sending verification code")
                }
            })
        } catch (error) {
            console.error('Error:', error);
        }
    }; */

    /* const handleVerifyWithMobile = () => {
        // navigate('/mobile-verification', { state: { email } });
        console.log("NOT IMPLEMENTED");
    }; */

    return (
        <div className="email-verification-body">
            <div className="email-verification-container">
                <div className="logo">BestDay2Marry</div>
                <div className="email-verification-form">
                    <h1>Email Authentication</h1>
                    <p>Please check your email for an authentication code.</p>
                    <form onSubmit={handleVerify}>
                        <div className="form-group">
                            <input
                                type="text"
                                placeholder="Verification Code"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                className={verificationError ? 'error' : ''}
                            />
                            {verificationError && <div className="error-message">{verificationError}</div>}
                        </div>
                        <button type="submit" className="submit">Verify</button>
                        <button type="button" className="tertiary-button" onClick={() => navigate(-1)}>Back</button>
                    </form>
                    <div className="alternative-verification">
                        {/* <p onClick={handleVerifyWithMobile}>Verify with mobile number instead</p> */}
                        {/* <p onClick={handleResendCode} className="resend-code">Resend the code</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailVerificationPage;
