import {
    TEMP_UNIT_KEY,
    PRECIP_UNIT_KEY,
    WIND_UNIT_KEY
  } from '../misc/constants';
  
  export const CHART_CONFIGS = {
    temperature: {
      dataKey: 'temperature',
      minKey: 'temperatureMin',
      maxKey: 'temperatureMax',
      color: '#FFA46B',
      fillId: 'colorTemp',
      domainMargin: 0,
      yAxisLabel: (unitSettings) => `Temperature (°${unitSettings[TEMP_UNIT_KEY]})`,
    },

    precipitation: {
      dataKey: 'precipitation',
      minKey: 'precipitationMin',
      maxKey: 'precipitationMax',
      color: '#5ED8FF',
      fillId: 'colorPrecip',
      domainMargin: 0,
      yAxisLabel: (unitSettings) => `Precipitation (${unitSettings[PRECIP_UNIT_KEY]})`,
    },

    allSky: {
      dataKey: 'watts',
      minKey: 'wattsMin',
      maxKey: 'wattsMax',
      color: '#FFF175',
      fillId: 'colorAllSky',
      domainMargin: 10,
      yAxisLabel: () => 'Solar Radiation (Watts)',
    },

    cloud: {
      dataKey: 'cloud',
      minKey: 'cloudMin',
      maxKey: 'cloudMax',
      color: '#B8B8B8',
      fillId: 'colorCloud',
      domainMargin: 0,
      yAxisLabel: () => 'Cloud Cover (%)',
    },

    rh2m: {
      dataKey: 'rh2m',
      minKey: 'rh2mMin',
      maxKey: 'rh2mMax',
      color: '#7DB9FF',
      fillId: 'colorRh2m',
      domainMargin: 0,
      yAxisLabel: () => 'Relative Humidity (%)',
    },

    windSpeed: {
      dataKey: 'windSpeed',
      minKey: 'windSpeedMin',
      maxKey: 'windSpeedMax',
      color: '#BBD531',
      fillId: 'colorWindSpeed',
      domainMargin: 1,
      yAxisLabel: (unitSettings) => `Wind Speed (${unitSettings[WIND_UNIT_KEY]})`,
    },
    temperatureMin: {
      dataKey: 'temperatureMin',
      minKey: 'temperatureMin',
      maxKey: 'temperatureMin',
      color: '#6B95FF',
      fillId: 'colorTempMin',
      domainMargin: 0,
      yAxisLabel: (unitSettings) => `Temperature Min (°${unitSettings[TEMP_UNIT_KEY]})`,
    },

    temperatureMax: {
      dataKey: 'temperatureMax',
      minKey: 'temperatureMax',
      maxKey: 'temperatureMax',
      color: '#FF6B6B',
      fillId: 'colorTempMax',
      domainMargin: 0,
      yAxisLabel: (unitSettings) => `Temperature Max (°${unitSettings[TEMP_UNIT_KEY]})`,
    },
  };