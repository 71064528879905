import IconWind from '../assets/icon-wind-color.png';
import IconRain from '../assets/icon-rain-color.png';
import IconTemp from '../assets/icon-temp-color.png';
import IconTempMax from '../assets/icon-temp-max-color.png';
import IconTempMin from '../assets/icon-temp-min-color.png';
import IconTempMaxLite from '../assets/icon-temp-max-lite.png';
import IconTempMinLite from '../assets/icon-temp-min-lite.png';
import IconSolar from '../assets/icon-solar.png';
import IconCloud from '../assets/icon-cloud.png';
import IconHumidity from '../assets/icon-humidity.png';
import IconWindLite from '../assets/icon-wind-lite.png';
import IconRainLite from '../assets/icon-rain-lite.png';
import IconTempLite from '../assets/icon-temp-lite.png';
import IconSolarLite from '../assets/icon-solar-lite.png';
import IconCloudLite from '../assets/icon-cloud-lite.png';
import IconHumidityLite from '../assets/icon-humidity-lite.png';

export const TAB_CONFIGS = [
    {
        id: 'temperature',
        iconActive: IconTemp,
        iconInactive: IconTempLite,
        alt: 'Temperature',
    },

    {
        id: 'precipitation',
        iconActive: IconRain,
        iconInactive: IconRainLite,
        alt: 'Precipitation',
    },
    
    {
        id: 'allSky',
        iconActive: IconSolar,
        iconInactive: IconSolarLite,
        alt: 'Solar Radiation'
    },

    {
        id: 'cloud',
        iconActive: IconCloud,
        iconInactive: IconCloudLite,
        alt: 'Cloud Cover'
    },

    {
        id: 'rh2m',
        iconActive: IconHumidity,
        iconInactive: IconHumidityLite,
        alt: 'Relative Humidity'
    },

    {
        id: 'windSpeed',
        iconActive: IconWind,
        iconInactive: IconWindLite,
        alt: 'Wind Speed'
    },

    {
        id: 'temperatureMin',
        iconActive: IconTempMin,
        iconInactive: IconTempMinLite,
        alt: "Temperature Min"
    },

    {
        id: 'temperatureMax',
        iconActive: IconTempMax,
        iconInactive: IconTempMaxLite,
        alt: "Temperature Max"
    }
  ];