import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import SettingsPage from '../pages/SettingsPage';
import IconHome from '../assets/icon-home.png';
import IconCog from '../assets/icon-cog.png';

import '../styles/SideMenu.css';

const SideMenu = ({ userId }) => {
  return (
    <div className="dashboard-side-menu">
      <Routes>
        <Route path="/settings" element={<SettingsPage />} />
      </Routes>
      <a href={`/dashboard/${userId}`} className="dashboard-side-menu-item">
        <img className="dashboard-small-icon" src={IconHome} alt="Home" />
      </a>
      <Link to={`/settings/${userId}`} className="dashboard-side-menu-item">
        <img className="dashboard-small-icon" src={IconCog} alt="Settings" />
      </Link>
    </div>
  );
};

export default SideMenu;