import { useEffect, useState } from 'react';
import { fetchUnitSettingsForUser } from '../utils/queries';

import {
  IMPERIAL, METRIC,
  TEMP_UNIT_KEY,
  FAHRENHEIT,
  IMPERIAL_UNITS,
  METRIC_UNITS,
} from '../misc/constants';

export function useUnitSettings(userId) {
  const [unitSettings, setUnitSettings] = useState(IMPERIAL_UNITS);
  const [unitSystem, setUnitSystem] = useState(IMPERIAL);

  useEffect(() => {
    async function fetchUnits() {
      const units = await fetchUnitSettingsForUser(userId);
      if (!units) {
        return; // fallback to default
      }
      if (units[TEMP_UNIT_KEY] === FAHRENHEIT) {
        setUnitSystem(IMPERIAL);
        setUnitSettings(IMPERIAL_UNITS);
      } else {
        setUnitSystem(METRIC);
        setUnitSettings(METRIC_UNITS);
      }
    }
    fetchUnits();
  }, [userId]);

  return { unitSettings, setUnitSettings, unitSystem, setUnitSystem };
}